import { Toaster } from 'react-hot-toast';
import './App.css';
import Router from './Router';
import 'Styles/commonStyles.scss';
import AutoLogout from 'Components/CommonComponents/AutoLogout';

function App() {
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: true,
  //     },
  //     "google_translate_element"
  //   );
  // };

  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  return (
    <AutoLogout>
      <Router />
      <Toaster position="top-center" reverseOrder={false} />
    </AutoLogout>
  );
}

export default App;
