import routes from 'Routes';
import { useBlacklistJwtToken } from 'ServiceHooks/authHooks';
import { useLayoutStore } from 'Store/LayoutStore';
import { DEFAULT_ERROR_MESSAGE } from 'Utilities/constants';
import { message } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AutoLogout = ({ children }) => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const logoutTime = 15 * 60 * 1000; // 15 minutes in milliseconds

  let logoutTimer;

  const {
    toggleNotification,
    clearReadNotifications,
    resetGridColumPreference,
  } = useLayoutStore((state) => ({
    notifications: state.notifications,
    toggleNotification: state.toggleNotification,
    clearReadNotifications: state.clearReadNotifications,
    resetGridColumPreference: state.resetGridColumPreference,
  }));

  const { mutateAsync: logout } = useBlacklistJwtToken({
    onError: async (error) => {
      const isLoggedOut =
        error?.error_info?.detail?.includes('is blacklisted');
      const type = isLoggedOut ? 'info' : 'error';
      const content = isLoggedOut
        ? 'Already logged out.'
        : DEFAULT_ERROR_MESSAGE;
      await messageApi.open({
        type,
        content,
      });
    },
  });

  // Reset timer on any user activity
  const resetTimer = () => {
    clearTimeout(logoutTimer);
    startTimer();
  };

  // Start timer to check for inactivity
  const startTimer = () => {
    logoutTimer = setTimeout(() => {
      handleLogout();
    }, logoutTime);
  };

  // Handle user logout
  const handleLogout = async() => {
    toggleNotification(false);
    clearReadNotifications();
    await logout();
    localStorage.removeItem('user');
    localStorage.removeItem('user_id');
    localStorage.removeItem('userPreference');
    localStorage.removeItem('company_id');
    localStorage.removeItem('yvi-persisted');
    resetGridColumPreference(); //removed for temp data grid development
    await navigate(routes.LOGIN);
  };

  useEffect(() => {
    // Start the inactivity timer
    startTimer();

    // Listen for user activity to reset timer
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('scroll', resetTimer);

    // Cleanup event listeners and timer on component unmount
    return () => {
      clearTimeout(logoutTimer);
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('scroll', resetTimer);
    };
  }, []);

  return children;
};

export default AutoLogout;
