import { Button, Drawer, message } from 'antd';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import 'Styles/Dashboard.scss';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useEffect, useState } from 'react';
import { Alert } from 'antd';
import toast from 'react-hot-toast';
import { useLayoutStore } from 'Store/LayoutStore';
import { isEmpty } from 'lodash';
import Accordion from 'Components/CommonComponents/Accordion/Accordion';
import iconAccordion from 'Assets/icons/accordionIcon.svg';
import { getIcons, getLabels } from 'Components/CommonComponents/Icons';
import closeIcon from 'Assets/icons/icon_close.svg';
import { useDragAndDrop } from 'ServiceHooks/dashboardHooks';

const ITEM_TYPE = 'ICON';

const DashBoardModal = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [messageApi, messageContextHolder] = message.useMessage();

  const { dashboard_preference, setDashboardPreference } =
    useLayoutStore();

  // const [data, setData] = useState({
  //   JOB: {
  //     VIEW_ACTIVE_JOB_TAB: true,
  //     VIEW_PENDING_JOB_TAB: true,
  //     VIEW_OVERDUE_JOB_TAB: false,
  //     VIEW_DRAFT_JOB_TAB: true,
  //     VIEW_HOLD_JOB_TAB: true,
  //     VIEW_ONBOARDING_JOB_TAB: false,
  //     VIEW_ONBOARDING_INPROGRESS_JOB_TAB: true,
  //     VIEW_AWAITING_QUESTIONNAIRE: true,
  //     VIEW_ALL_STATUS: false,
  //   },
  //   CANDIDATES: {
  //     VIEW_FINALIZED_CANDIDATE_TAB: true,
  //     VIEW_CONFLICTED_CANDIDATE_TAB: false,
  //   },
  //   INTERVIEW: {
  //     VIEW_UPCOMMING__INTERVIEWS_TAB: true,
  //     VIEW_CALENDER_INTERVIEWS_TAB: false,
  //   },
  // });

  const { mutate: CreateDragandDrop } = useDragAndDrop({
    onSuccess: (data, variables, context) => {},
    onError: (error, variables, context) => {
      const message = error.response?.data?.error_info?.name.pop();
    },
  });

  function updateValue(mainKey, subKey, newValue) {
    // Clone the dashboard_preference object
    const preference = JSON.parse(JSON.stringify(dashboard_preference)); // Deep clone if nested objects exist

    if (preference[mainKey] && preference[mainKey][subKey] !== undefined) {
      preference[mainKey][subKey] = newValue;
    } else {
      console.error('Invalid keys provided.');
    }

    return preference; // Return the modified copy
  }
  const handleItemDropOutside = (subKey, mainKey, value) => {
    console.log('State after update:', subKey);
    CreateDragandDrop({ dashboard_setting: subKey, is_active: true });

    if (!value) {
      // If value is false, you might want to update the dashboard preference to set it to true
      const updatedValue = updateValue(mainKey, subKey, true);
      setDashboardPreference(updatedValue);
      console.log(`Updated ${subKey} in ${mainKey} to true`);
    }
  };

  // Draggable Icon Component
  const DraggableIcon = ({ subKey, mainKey, value }) => {
    const [, dragRef] = useDrag({
      type: ITEM_TYPE,
      item: { subKey, mainKey, value }, // Pass current value of the item
      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop && !item.value) {
          // If the item is dropped outside and its value is false
          handleItemDropOutside(item.subKey, item.mainKey, item.value);
        }
      },
    });
    const backgroundColor = value ? 'bg-gray-100' : 'bg-white';
    const handleDoubleClick = () => {
      // Call handleItemDropOutside with the icon's specific keys and value
      handleItemDropOutside(subKey, mainKey, value);
    };
    return (
      <>
        {getIcons(subKey) && (
          <div
            ref={dragRef}
            className={`${backgroundColor} w-[29%] rounded-lg border-[0.5px] border-[#D7D7D7] ml-2 flex justify-center items-center mt-4  ${
              value ? 'cursor-not-allowed' : ''
            }`}
            onDoubleClick={handleDoubleClick}
          >
            <div className="p-4">
              <div className="flex justify-center">
                <img
                  src={getIcons(subKey)}
                  alt={`${subKey} icon`}
                  className="cursor-pointer"
                />
              </div>
              <div>
                <span className="flex justify-center text-sm text-center font-normal mt-2">
                  {getLabels(subKey)}
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  // ant-drawer-content-wrapper
  console.log('column_preferecess', dashboard_preference);

  return (
    <div>
      <Drawer
        title={null}
        width={400}
        placement="right"
        onClose={props.onClose}
        mask={false}
        open={props.open}
        getContainer={() => props?.drawerRef.current}
        // drawerStyle={{
        //   marginTop: '20px', // Add margin to the drawer
        // }}
        closeIcon={
          <div style={{ position: 'absolute', right: 16, top: 16 }}>
            <img
              src={closeIcon}
              alt="Close"
              style={{ width: 30, cursor: 'pointer' }}
            />
          </div>
        }
      >
        <div className="mx-2">
          <DndProvider backend={HTML5Backend}>
            <div>
              <Accordion
                bordered={false}
                className=" bg-white "
                expandIcon={({ isActive }) => {
                  return (
                    <div className="customExpandIcon">
                      <img
                        alt="accordion"
                        src={iconAccordion}
                        className={`${!isActive ? '' : 'rotate-180'}`}
                      />
                    </div>
                  );
                }}
                accordion
                items={Object.keys(dashboard_preference)?.map(
                  (mainKey, dataIndex) => {
                    let items;
                    // (key) => console.log('data', data[key]),.map((items, dataIndex) => {
                    return {
                      label: (
                        <div className="mt-6 ">
                          <p className="text-sm md:text-lg w-full">
                            {mainKey}
                          </p>
                        </div>
                      ),
                      children: (
                        <div className="bg-white w-full flex flex-wrap mt-4 mb-6">
                          {Object.keys(dashboard_preference[mainKey]).map(
                            (subKey, index) => (
                              <DraggableIcon
                                key={index}
                                subKey={subKey}
                                mainKey={mainKey}
                                value={
                                  dashboard_preference[mainKey][subKey]
                                } // Pass the current value of the item
                              />
                            ),
                          )}
                        </div>
                      ),
                    };
                  },
                )}
                defaultActiveKey={[]}
              />
            </div>
          </DndProvider>
        </div>
      </Drawer>
    </div>
  );
};
export default DashBoardModal;
